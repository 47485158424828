import { Translation } from 'projects/_shared/models/enums';
import { createTranslateLoader } from 'projects/_shared/services/translation-loader.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'projects/_shared/shared/shared.module';
import { IAppState, rootReducer } from 'projects/_shared/shared/store/app.store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EnvironmentSpecificService } from '../app/configuration/environment-specific-service'
import { CustomerdigitalServiceLibModule, LibConfigurationProvider, UrlBuilderService, 
  StorageService, SsoModule, EpaasService, LoadExternalFileService, TimeoutService } from 'customerdigital-service-lib';
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { AuthService } from 'projects/_shared/web-service/auth.service';
import { LoggerHelperService } from 'projects/_shared/shared/helper-service/logger-helper.service';
import { LanguageService } from 'projects/_shared/shared/helper-service/language.service';
import { GuardService } from 'projects/_shared/shared/helper-service/guard.service';
import { LibConfiguration } from 'projects/_shared/shared/helper-service/lib-config.service';
import { ActivityLoggingFactory } from 'projects/_shared/core/logging/logging.factory';
import { ClientLogWriterService } from 'projects/_shared/web-service/client-log-writer.service';
import { ClientLogWriterClient } from 'projects/_shared/core/gatewayapi/logger-service.api';
import { ParentActivityIdActions } from 'projects/_shared/shared/store/reducers/parent-activity-id.reducer';
import { UserActivityIdActions } from 'projects/_shared/shared/store/reducers/user-activityid.reducer';
import { UserService } from 'projects/_shared/shared/helper-service/user.service';
import { API_BASE_URL } from '../../../_shared/core/helper/api-helper';
import { AuthorizationInterceptor } from 'projects/_shared/core/interceptor/authorization.interceptor';
import { ApplicationConfigActions } from 'projects/_shared/shared/store/reducers/application-config.reducer';
import { EnvironmentConfigActions } from 'projects/_shared/shared/store/reducers/environment-config.reducer';
import { LanguageActions } from 'projects/_shared/shared/store/reducers/language.reducer';
import { AuthServiceClient } from 'projects/_shared/core/gatewayapi/auth-service.api';
import { ApplicationConfig } from 'projects/_shared/models/configuration/application.config';
import { SessionTimeoutDialogService } from 'projects/_shared/shared/helper-service/session-timout-dialog.service';
import { FaqActions } from 'projects/_shared/shared/store/reducers/faq.reducers';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomerDigitalUiLibModule, DialogService, FsAuthHubFooterModule, FsHeaderWebModule, FsIconModule, FsTopnavModule } from 'customerdigital-ui-lib';
import { UserIsTimedOutActions } from 'projects/_shared/shared/store/reducers/user-is-timed-out.reducer';
import { UserInactivityDialogCloseActions } from 'projects/_shared/shared/store/reducers/user-inactivity-dialog-close.reducer';
import { MatMenuModule } from '@angular/material/menu';
import { BmwCardService } from 'projects/_shared/web-service/bmw-card.service';
import { BmwCardServiceClient } from 'projects/_shared/core/gatewayapi/bmw-card.service.api';
import { FeatureIndicatorService } from 'projects/_shared/web-service/feature-indicator.service';
import { FeatureIndicatorServiceClient } from 'projects/_shared/core/gatewayapi/feature-indicator-service.api';
import { TermsModule } from './terms/terms.module';
import { GCDMServiceClient } from 'projects/_shared/core/gatewayapi/gcdm-service.api';
import { GCDMService } from 'projects/_shared/shared/helper-service/gcdm-service.service';
import { IsEpassInitializedActions } from 'projects/_shared/shared/store/reducers/is-epaas-initialized.reducer';
import { FilterService } from 'projects/_shared/shared/helper-service/filter.service';
import { CannotAuthenticateActions } from 'projects/_shared/shared/store/reducers/cannot-authenticate.reducer';
import { GlobalErrorHandler } from 'projects/_shared/errorhandler/global-error-handler'
import { GlobalErrorActions, GlobalErrorReducers } from 'projects/_shared/shared/store/reducers/global-error-message.reducer';
import { Store, StoreModule } from '@ngrx/store';
import { SessionTimeoutService } from 'projects/_shared/shared/helper-service/session-timeout.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PartnerContactServiceClient } from 'projects/_shared/core/gatewayapi/partner-contact-service.api';
import { PartnerContactService } from 'projects/_shared/shared/helper-service/partner-contact.service';
import { AuthenticatorHelperService } from 'projects/_shared/shared/helper-service/authenticator-helper.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SsoModule,
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
    CustomerdigitalServiceLibModule.forRoot({
      config: {
        provide: LibConfigurationProvider,
        useClass: LibConfiguration,
        deps: [Store]
      },
    },
      RouterService,
      AuthService,
      LoggerHelperService,
      LanguageService,
      GuardService,
      SessionTimeoutDialogService),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    StoreModule.forRoot (rootReducer, {
      runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true
      }
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    CustomerDigitalUiLibModule,
    FsHeaderWebModule,
    FsTopnavModule,
    FsAuthHubFooterModule, 
    MatMenuModule, 
    FsIconModule,
    TermsModule
  ],
  providers: [
    AuthService,
    PartnerContactServiceClient,
    PartnerContactService,
    AuthenticatorHelperService,
    {
      provide: "RouterService",
      useFactory: (ServiceFactory),
      deps: [RouterService]
    },
    {
      provide: 'AuthService',
      useFactory: (ServiceFactory),
      deps: [AuthService]
    },
    {
      provide: 'LanguageService',
      useFactory: (ServiceFactory),
      deps: [LanguageService]
    },
    {
      provide: 'GuardService',
      useFactory: (ServiceFactory),
      deps: [GuardService]
    },
    {
      provide: 'LoggerService',
      useFactory: (ServiceFactory),
      deps: [LoggerHelperService]
    },
    ActivityLoggingFactory,
    UrlBuilderService,
    LanguageService,
    TranslateService,
    UrlBuilderService,
    StorageService,
    EpaasService, 
    LoadExternalFileService,
    ClientLogWriterService,
    ClientLogWriterClient,
    ParentActivityIdActions,
    UserActivityIdActions,
    EnvironmentSpecificService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerLoaderFactory,
      deps: [EnvironmentSpecificService, UserService, Store],
      multi: true
    },
    {
      provide: API_BASE_URL,
      useFactory: BaseUrlLoaderFactory,
      deps: [Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    ApplicationConfigActions,
    EnvironmentConfigActions,
    LanguageActions,
    AuthServiceClient,
    FaqActions,
    SpinnerHelperService,
    IndicatorActions,
    DialogService,
    SessionTimeoutService,
    TimeoutService,
    UserIsTimedOutActions,
    UserInactivityDialogCloseActions,
    BmwCardService,
    BmwCardServiceClient,
    FeatureIndicatorService,
    FeatureIndicatorServiceClient,
    GCDMService,
    GCDMServiceClient,
    IsEpassInitializedActions,
    CannotAuthenticateActions,
    FilterService,
    GlobalErrorReducers,
    GlobalErrorHandler, {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    GlobalErrorActions

  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
  constructor(
    translate: TranslateService
  ) {
    translate.addLangs([Translation.MINI_CA_ENGLISH, Translation.MINI_CA_FRENCH]);
    translate.setDefaultLang(Translation.MINI_CA_ENGLISH);
    translate.use(Translation.MINI_CA_ENGLISH);
  }
}


export function HttpLoaderFactory(http: HttpClient) {

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function AppInitializerLoaderFactory(environmentSpecificService: EnvironmentSpecificService, userService: UserService, store: Store<IAppState>) {
  var appConfig: ApplicationConfig = environmentSpecificService.loadApplicationConfig();
  userService.setApplicationConfig(appConfig);
  return () => environmentSpecificService.loadEnvironment()
    .subscribe(e => {
      userService.setEnvironmentConfig(e);
    }
    );
}

export function BaseUrlLoaderFactory(store: Store<IAppState>) {
  let gatewayApiBaseUrl: string | undefined;
  store.select(state => state.EnvironmentConfig?.GATEWAY_API_BASE_URL).subscribe(x => gatewayApiBaseUrl = x)
  return gatewayApiBaseUrl;
}

export function ServiceFactory(injectedService: any) {
  return injectedService;
}
